import React, { useMemo } from "react"
import { HStack, Text, Skeleton, Heading } from "@chakra-ui/react"
import { Helmet } from "react-helmet"

import { useShopify } from "@app/hooks/useShopify"
import ProductComparePrice from "@app/components/Product/ProductComparePrice"
import { useProductContext } from "@app/providers/product"
import { useCheckoutContext } from "@app/providers/checkout"

type Props = {
  loading?: boolean
  compareText: string | undefined
}

const ProductTemplatePrice: React.FC<Props> = ({ loading, compareText }) => {
  const { bundlePrice, productBundle } = useProductContext()
  const { checkout } = useCheckoutContext()
  const { formatMoney } = useShopify()

  const isLoaded = bundlePrice !== undefined && !loading
  const price = useMemo(() => formatMoney(bundlePrice || 0, checkout?.currencyCode), [bundlePrice, checkout?.currencyCode, formatMoney])

  const priceText = useMemo(() => {
    if (!productBundle.step1) return `from ${price}`
    return price
  }, [productBundle.step1, price])

  return (
    <>
      <Helmet>
        <script src="https://js.afterpay.com/afterpay-1.x.js" id="afterpay-placement-script" async></script>
      </Helmet>

      <Skeleton w={!isLoaded ? "45" : "auto"} h={!isLoaded ? "8" : "auto"} isLoaded={isLoaded} sx={{ "afterpay-placement": { my: "0" } }}>
        <HStack spacing="3" alignItems="center" mb={{ base: "2", lg: "2.5" }}>
          <Heading as={Text} size="h5" whiteSpace="nowrap" textTransform="capitalize" color="typography.headlines900">
            {priceText}
          </Heading>

          {compareText ? <ProductComparePrice compareText={compareText} /> : null}
        </HStack>

        <afterpay-placement data-locale="en_AU" data-currency="AUD" data-amount={`${bundlePrice}`} data-size="sm"></afterpay-placement>
      </Skeleton>
    </>
  )
}

export default React.memo(ProductTemplatePrice)
